<template>
  <div class="base-input"
    :class="[{ 'has-placeholder': placeholderText }, { 'has-error': hasError }]">
      <label v-if="labelText">{{ labelText }}</label>
      <input v-model="localInputValue" :type="type" :placeholder="placeholderText" />
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    defaultValue: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: (value) => ['text', 'password', 'number', 'email'].indexOf(value) !== -1,
    },
    placeholderText: {
      type: String,
      required: false,
    },
    labelText: {
      type: String,
      required: false,
    },
    hasError: {
      type: Boolean,
      required: false,
    },
  },
  components: {},
  data: () => ({
    localInputValue: undefined,
  }),
  created() {
    if (this.defaultValue) {
      this.localInputValue = this.defaultValue;
    }
  },
  computed: {},
  watch: {
    defaultValue(val) {
      this.localInputValue = val;
    },
    localInputValue(val) {
      this.$emit('input', val);
    },
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
$inputWithPlaceholderHeight: 38px;
$inputHeight: 30px;

input {
  border-radius: $globalBorderRadius;
  border: 2px solid $globalBorderColor;
  background-color: $white;
  padding: 0 17px;
  font-size: 12px;
  width: 100%;
  color: $globalFontColor;
  font-weight: 500;
  transition: $transitionBase;
  height: $inputHeight;

  @media screen and (max-width: $breakpointDownSm) {
    height: calc(#{$inputHeight} + 14px);
    font-size: 15px;
  }

  &:focus {
    outline: none;
    border-color: $primaryBlue;
  }
}

label {
  font-size: 12px;
  font-weight: 500;
  padding: 0 0 0 14px;
  line-height: 1;
  margin: 0 0 4px;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 15px;
    margin: 0 0 7px;
    padding: 0;
  }
}

.has-placeholder {
  input {
    height: $inputWithPlaceholderHeight;
    border-color: $globalSecondaryBorderColor;

    @media screen and (max-width: $breakpointDownSm) {
      height: calc(#{$inputWithPlaceholderHeight} + 6px);
    }

    &::placeholder {
      color: rgba($globalFontColor, 0.5);
    }

    &:focus {
      outline: none;
      border-color: $primaryBlue;
    }
  }
}

.has-error {
  input {
    border-color: $globalErrorColor !important;
  }

  label {
    color: $globalErrorColor !important;
  }
}

</style>
