<template>
  <div id='reset-password' ref="resetPassword">
    <div class="container webshop-container">
      <div class="content-wrapper">
        <error-toast
          :isVisible="toast.isVisible"
          :type="toast.type"
          :text="toast.text"
          :color="toast.color"
        />

        <h1 class="title">{{ $t('pages.resetPassword.title') }}</h1>
        <p>{{ $t('pages.resetPassword.information') }}</p>
        <div class="form-wrapper">
          <base-input
            ref="passwordInput"
            :type="'password'"
            :hasError="inputs.password.hasError"
            :placeholderText="$t('pages.resetPassword.inputs.password')"
            @input="handleInput('password', ...arguments)"
          />
          <base-input
            ref="passwordAgainInput"
            :type="'password'"
            :hasError="inputs.passwordAgain.hasError"
            :placeholderText="$t('pages.resetPassword.inputs.passwordAgain')"
            @input="handleInput('passwordAgain', ...arguments)"
          />
          <base-button
            :isDisabled="isBtnDisabled"
            :isLoading="isBtnLoading"
            :text="$t('pages.resetPassword.buttons.submit')"
            @clicked="handleBtnClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import scroll from '@/components/shared/mixins/scroll';

import BaseInput from '@/components/shared/elements/inputs/BaseInput';
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';

export default {
  name: 'ResetPasswordScreen',
  mixins: [scroll],
  props: {},
  components: {
    BaseInput,
    BaseButton,
    'error-toast': () => import('@/components/shared/elements/toasts/ErrorToast'),
  },
  data: () => ({
    isBtnLoading: false,
    token: undefined,
    inputs: {
      password: {
        hasError: false,
        value: undefined,
      },
      passwordAgain: {
        hasError: false,
        value: undefined,
      },
    },
    toast: {
      isVisible: false,
      text: undefined,
      type: 'error',
    },
    isBtnDisabled: false,
  }),
  created() {
    this.token = this.$route.params.token || this.$route.query.token;
  },
  computed: {},
  methods: {
    ...mapActions({
      resetPassword: 'users/resetPassword',
    }),
    handleInput(input, value) {
      this.inputs[input].value = value;
    },
    validatePasswords() {
      if (this.inputs.password.value === undefined || this.inputs.password.value.length === 0) {
        this.inputs.password.hasError = true;
        this.inputs.passwordAgain.hasError = true;

        this.toast.isVisible = true;
        this.toast.text = this.$t('general.errors.missingFields');

        return false;
      } else if (this.inputs.passwordAgain.value === undefined || this.inputs.passwordAgain.value.length === 0) {
        this.inputs.password.hasError = true;
        this.inputs.passwordAgain.hasError = true;

        this.toast.isVisible = true;
        this.toast.text = this.$t('general.errors.missingFields');

        return false;
      } else if (this.inputs.password.value !== this.inputs.passwordAgain.value) {
        this.inputs.password.hasError = true;
        this.inputs.passwordAgain.hasError = true;

        this.toast.isVisible = true;
        this.toast.text = this.$t('general.errors.password');

        return false;
      }

      this.inputs.password.hasError = false;
      this.inputs.passwordAgain.hasError = false;
      this.toast.isVisible = false;

      return true;
    },
    handleBtnClick() {
      const requestObj = {
        password: this.inputs.password.value,
        passwordResetCode: this.token,
      };

      const validateResult = this.validatePasswords();

      if (validateResult) {
        this.isBtnLoading = true;

        this.resetPassword(requestObj).then(() => {
          this.isBtnLoading = false;
          this.$refs.passwordInput.localInputValue = undefined;
          this.$refs.passwordAgainInput.localInputValue = undefined;

          this.toast.isVisible = true;
          this.toast.type = 'success';
          this.toast.text = this.$t('pages.resetPassword.success.toastText');
          this.isBtnDisabled = true;
        }).catch((error) => {
          this.isBtnLoading = false;
          this.toast.isVisible = true;
          this.toast.type = 'error';
          this.toast.text = error.data.message;

          this.scrollToToast('resetPassword');
        });
      } else {
        this.scrollToToast('resetPassword');
      }
    },
  },
};
</script>

<style lang='scss' scoped>
$contentMaxWidth: 670px;
$inputWidth: 210px;

.error-toast {
  margin: 0 50px 20px;

  @media screen and (max-width: $breakpointDownSm) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

#reset-password {
  padding: 100px 0 320px;
}

.content-wrapper {
  border-radius: $globalBorderRadius;
  box-shadow: $lightDropdownShadow;
  max-width: $contentMaxWidth;
  margin: 0 auto;
  background-color: $white;
  padding: 30px 40px 40px;
  text-align: center;

  @media screen and (max-width: $breakpointDownXs) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.title {
  @include titleMixin();
  margin: 0 0 25px;
}

p {
  font-size: 12px;
  line-height: 13px;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 13px;
  }
}

.form-wrapper {
  max-width: $inputWidth;
  margin: 20px auto 0;

  @media screen and (max-width: $breakpointDownSm) {
    max-width: 70%;
  }

  .base-input {
    margin: 0 0 15px;

    &::v-deep input {
      text-align: center;
    }
  }
}

.button-base {
  margin: 5px 0 0;
  display: inline-block;
  width: 100%;

  @media screen and (max-width: $breakpointDownSm) {
    height: 45px;
  }
}
</style>
