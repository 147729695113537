export default {
  methods: {
    scrollToToast(refName) {
      this.$smoothScroll({
        scrollTo: this.$refs[refName],
        duration: 800,
        offset: -100,
        updateHistory: false,
      });
    },
  },
};
